import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { Modal, Typography, Form, Select, Input, Button, Space, message, Checkbox, DatePickerProps, DatePicker, TableColumnsType, Table, Tag } from "antd";
import { addSubscriptionFeature, cancelSubscriptionFeature, createFirstInvoice, createManualSubscription, deleteOrganizationPlayer, deleteUser, disableSubscription, getAvailSubscriptionFeatureList } from "../../apiBridge";
import { IOrganizationPlayerListItem, IOwnerSummary, ISubscriptionFeature } from "../../interface";
import { MessageInstance } from "antd/es/message/interface";

const { Title, Text, Link } = Typography;

interface Props {
  isOpen: boolean;
  user: IOwnerSummary | null;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
  notifController: MessageInstance;
}

const OwnerSubscriptionFeatureModal: React.FC<Props> = ({
    isOpen,
    controller,
    notifController,
    user,
    reloadController
}) => {

    const [form] = Form.useForm();
    const [isOpenAdd, SetIsOpenAdd] = useState(false);

    const [loadingState, setLoadingState] = useState(false);
    const [dateDelta, setDateDelta] = useState(-1);

    const [dataItems, setDataItems] = useState([]);

    const columns: TableColumnsType<ISubscriptionFeature> = [
        {
            title: "Feature",
            key: "name",
            render: (_, row) => (
              <div>
                {row.name}
              </div>
            ),
        },
        {
            title: "Expired",
            key: "expire_at",
            render: (_, row) => (
                <div>
                  {row.expire_at !== "0001-01-01T00:00:00Z" ? (
                    new Date(row.expire_at) > new Date() ? (
                      <Tag color="green">{new Date(row.expire_at).toLocaleDateString()}</Tag>
                    ) : (
                      <Tag color="red">{new Date(row.expire_at).toLocaleDateString()}</Tag>
                    )
                  ) : null}
                </div>
              ),
        },
        {
            title: "Remark",
            key: "remark",
            render: (_, row) => (
                <div>
                  {row.remark}
                </div>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, row) => (
                <div>
                    <Link onClick={() => cancelSubscription(row)}>Cancel</Link>
                </div>
            ),
        },

    ];

    const addFeatureName = Form.useWatch("name", form);

    const cancelSubscription = async(item: ISubscriptionFeature) => {
        if (!user?.organization?.organization_id) {
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (!confirm("cancel?")) {
            return false;
        }

        setLoadingState(true)
        let res = await cancelSubscriptionFeature(user.organization.organization_id, item.id)
        if (res) {
            const data = await res.json();

            if (data.error) {
                notifController.error(data.error)
            } else {
                notifController.success("cancel success")
                loadSubscriptionFeatures()
            }
        }
        setLoadingState(false);
    }

    const loadSubscriptionFeatures = async() => {
        if (!user?.organization?.organization_id) {
            notifController.info("no organization")
            controller(false);

            return;
        }

        setLoadingState(true)
        let res = await getAvailSubscriptionFeatureList(user?.organization?.organization_id)

        if (res) {
            const data = await res.json();

            setDataItems(data.result);
        }
        setLoadingState(false);
    }

    const setSubscriptionDate: DatePickerProps['onChange'] = (date, dateString) => {
        const today = new Date();
        const diff = date?.diff(today, 'days');
        setDateDelta(diff);
    };

    const onFinished = async (values: any) => {
        if (!user?.organization?.organization_id) {
            return ;
        }

        if (values.name === "") {
            notifController.info("please select feature");
            return ;
        }

        if (dateDelta < 0) {
            notifController && notifController.open({
              type: "error",
              content: "Please select a date in the future.",
            });
            setDateDelta(-1);
            return;
        }

        let options :any = {};
        if (values.name === "performance_mode") {
            if (!values["options.max_count"]) {
                notifController.info("please input max count")
                return
            }

            options.max_count = values["options.max_count"]
        }

        setLoadingState(true)
        let res = await addSubscriptionFeature(user.organization.organization_id, values.name, dateDelta, values.remark, options)
        if (res) {
            const data = await res.json()

            if (data.error) {
                notifController.error(data.error)
            } else {
                notifController.success("feature create success")
                loadSubscriptionFeatures()
                SetIsOpenAdd(false);
            }
        }
        setLoadingState(false)
    }

    useEffect(() => {
        if (isOpen) {
            setDataItems([]);
            form.resetFields()

            loadSubscriptionFeatures();
        }
    }, [isOpen])

    return <>
        <Modal open={isOpen} centered footer={null} closeIcon={false} maskClosable={false}>
            <div style={{ marginBottom: 20 }}>
                <Title level={4}>Features</Title>
            </div>

            <Button onClick={() => SetIsOpenAdd(true)}>Add</Button>


            <Table
                pagination={false}
                  columns={columns}
                  dataSource={dataItems}
                  loading={loadingState}
                  rowKey="key"
                  scroll={{
                    x: "max-content",
                  }}
                />

            <div style={{"marginTop": 20}}>
                <Button onClick={() => controller(false)}>Close</Button>
            </div>
        </Modal>

        <Modal open={isOpenAdd} centered footer={null} closeIcon={false} maskClosable={false}>
            <div style={{ marginBottom: 20 }}>
                <Title level={4}>Add Feature</Title>
            </div>

            <Form
                name="editFeatureForm"
                initialValues={{
                    name: "",
                    remark: "",
                    options: {}
                }}
                onFinish={onFinished}
                autoComplete="off"
                form={form}
                validateTrigger="onSubmit"
                requiredMark={false}
                layout="vertical"
            >
                <Form.Item label="Feature" name="name">
                    <Select>
                        <Select.Option key="" value="">Please select</Select.Option>
                        <Select.Option key="performance_mode" value="performance_mode">Performance Mode</Select.Option>
                        <Select.Option key="tournament" value="tournament">Tournament</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Expire Date">
                    <DatePicker onChange={setSubscriptionDate} minDate={dayjs().add(1, 'd')} allowClear showNow={false} />
                </Form.Item>

                <Form.Item label="Remark" name="remark">
                    <Input />
                </Form.Item>

                {
                    addFeatureName === "performance_mode" ? <>
                        <Form.Item name="options.max_count" label="Max Room Count">
                            <Input />
                        </Form.Item>
                    </> : <></>
                }

                <div style={{ textAlign: "right" }}>
                <Space>
                    <Button onClick={() => SetIsOpenAdd(false)}>Cancel</Button>

                    <Form.Item style={{ margin: 0 }}>
                    <Button type="primary" htmlType="submit" loading={loadingState}>
                        Add
                    </Button>
                    </Form.Item>
                </Space>
                </div>
            </Form>
        </Modal>
    </>
}

export default OwnerSubscriptionFeatureModal