import { useState } from "react";
import DebounceSelect from "./_debounceSelect"

interface Props {
    accountTypes: string[]
    change: React.Dispatch<React.SetStateAction<UserValue>>;
}

interface UserValue {
    label: string;
    value: string;
  }

const AccountSearchSelect: React.FC<Props> = ({
    accountTypes,
    change
}) => {
    const [value, setValue] = useState<UserValue[]>([]);


    async function fetchUserList(keyword: string): Promise<UserValue[]> {
        console.info("fetch user list...", keyword);
        return [
            {label: "Test 1", value: "Test1"},
            {label: "Test 2", value: "Test2"}
        ];
    }

    return <>
        <DebounceSelect value={value} fetchOptions={fetchUserList} placeholder={"Select " + accountTypes.join(" / ")} onChange={(newValue) => {
        setValue(newValue as UserValue[]);
      }}></DebounceSelect>
    </>
}

export default AccountSearchSelect
