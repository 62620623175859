import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
  RouterProvider,
} from "react-router-dom";

// Import Pages
import LoginPage from "./loginpage";
import RootLayout from "./rootlayout";
import AllAdmins from './landing/allAdmins';
import Owners from "./landing/allOwners";
import AuditLogs from './landing/auditLog'
import SettingsPage from "./landing/settings";
import CreateAccountPage from './landing/account/createAccount'

import {
  getAdminUsers,
  getAllDistributors,
  getAllOrganizations,
  getAllOwners,
  getAuthUser,
  getMessageInbox,
  getMessageOutbox,
  getOrganizationPlayers,
  getOwnerDetail,
  getUserRequestToUser,
  queryUserRequestList,
} from "./apiBridge";

import "antd/dist/reset.css";
import "./App.css";
import {
  IAdminUser,
  IAuthUser,
  IDistributionUser,
  IMessageListItem,
  IOrganization,
  IOrganizationPlayerListItem,
  IOwnerDetail,
  IOwnerSummary,
  IRequestParentToUser,
  IRequestTo,
} from "./interface";

import { ConfigProvider } from "antd";
import "@fontsource/poppins";
import SignleOrganization from "./landing/singleOrganization";

async function getUserLoader({ request }: LoaderFunctionArgs) {
  const res = await getAuthUser();
  console.log(request);
  if (res.status === 200) {
    const data = (await res.json()) as IAuthUser;
    return data;
  }
  return redirect("/login");
}

async function redirectLoader({ request }: LoaderFunctionArgs) {
  console.log("check auth");
  const res = await getAuthUser();
  if (res.status === 200) {
    const { account_role } = (await res.json()) as IAuthUser;
    return redirect("/owners");
  }
  return null;
}

async function getAllDistributorsLoader() {
  console.log("Getting all disributors");
  const res = await getAllDistributors();
  if (res.status === 200) {
    const { users } = (await res.json()) as {
      users: IDistributionUser[];
    };
    return users;
  } else if (res.status === 401) {
    return redirect("/login");
  }
}

async function getAdminUsersLoader() {
  const res = await getAdminUsers();
  if (res.status === 200) {
    const { users } = (await res.json()) as {
      users: IAdminUser[];
    };
    return users;
  } else if (res.status === 401) {
    return redirect("/login");
  }
}

async function getSingleOrganizationLoader({params} : LoaderFunctionArgs) {
  const { organization_id } = params;
  const res = await getOrganizationPlayers(String(organization_id))
  if (res.status === 200) {
    const data = (await res.json()) as {
      users: IOrganizationPlayerListItem[];
      organization: { id: string; name: string };
    };

    return data
  } else if (res.status === 401) {
    return redirect("/login");
  }
}

async function homeRedirectLoader({ params }: LoaderFunctionArgs) {

  
  return redirect("/owners");
}

async function getOwnerLoader() {
  console.log("Getting all owners");
  const res = await getAllOwners();
  if (res.status === 200) {
    const { users } = (await res.json()) as {
      users: IOwnerSummary[];
    };
    return users;
  } else if (res.status === 401) {
    return redirect("/login");
  }
  return null
}

async function getMessagesLoader({params} : LoaderFunctionArgs) {
  const {type} = params;

  if (type === 'inbox') {
    const res = await getMessageInbox(false, 0, 20);
    const unreadRes = await getMessageInbox(true, 0, 20);

    if (res.status === 200) {
      const data = await res.json() as {
        count: number,
        messages: IMessageListItem[]
      }

      const unreadData = await unreadRes.json() as {
        count: number,
        messages: IMessageListItem[]
      }

      return {
        messages: data.messages,
        unread: unreadData.count,
        count: data.count
      }
    }
  } else if (type === 'outbox') {
    const res = await getMessageOutbox(false, 0, 20);
    if (res.status === 200) {
      const data = await res.json() as {
        count: number,
        messages: IMessageListItem[]
      }

      return data;
    }
  } else if (type === 'draft') {
    const res = await getMessageOutbox(true, 0, 20)
    if (res.status === 200) {
      const data = await res.json() as {
        count: number,
        messages: IMessageListItem[]
      }

      return data
    }
  }

  return null;
}

async function getUserRequestLoader({params}: LoaderFunctionArgs) {
  const { type } = params;

  const res = await queryUserRequestList(0, 20, type === 'review')
  if (res.status === 200) {
    const data = await res.json()

    console.info(data)
    return data
  }

  return null;
}

async function createUserRequestLoader({params}: LoaderFunctionArgs) {
  const { to } = params;
  let parts = to?.split(":")

  if (parts && parts.length == 2) {
    let requestResult = {
      from: to,
      parent_id: "",
      parent_name: "",
      parent_role: "",
      users: []
    } as IRequestTo;

    const userRes = await getUserRequestToUser()
    if (userRes.status === 200) {
      const parentRes = await userRes.json() as IRequestParentToUser;
      
      requestResult.parent_id = parentRes.parent_id
      requestResult.parent_name = parentRes.parent_name;
      requestResult.parent_role = parentRes.parent_role;
    }

    /*
    if (parts[0] === "distributor") {
      let distribution_name :string = parts[1]
      console.log("Getting distributor " + distribution_name);
      const res = await getDistributorDetail({ distribution_name });
      if (res.status === 200) {
        const data = (await res.json()) as IDistributionDetail;

        let to_users = [];
        for (let i = 0; i < data.distribution_members.length; i++) {
          to_users.push({
            user_id: data.distribution_members[i].user_id,
            name: data.distribution_members[i].name,
            role: null,
            blocked: data.distribution_members[i].blocked
          })
        }

        requestResult.users = to_users

        return requestResult;
      } else if (res.status === 401) {
        return redirect("/login");
      }
    }*/
  }
  return null;
}

// Get Organizations using the owner_id
async function getSingleOwnerLoader({ params }: LoaderFunctionArgs) {
  const { owner_id } = params;
  if (owner_id) {
    console.log("Getting owners " + owner_id);
    const res = await getOwnerDetail({ owner_id });
    if (res.status === 200) {
      const data = (await res.json()) as IOwnerDetail;
      return data;
    } else if (res.status === 401) {
      return redirect("/login");
    }
  } else {
    console.log("Getting owners from token");
    const res = await getOwnerDetail();
    if (res.status === 200) {
      const data = (await res.json()) as IOwnerDetail;
      return data;
    } else if (res.status === 401) {
      return redirect("/login");
    } else if (res.status === 303) {
      return redirect("/owners");
    }
  }
}

async function getAllOrganizationsLoader() {
  console.log("Getting all organizations");
  const res = await getAllOrganizations();
  if (res.status === 200) {
    const { organizations } = (await res.json()) as {
      organizations: IOrganization[];
    };
    return organizations;
  } else if (res.status === 401) {
    return redirect("/login");
  }
  return null;
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
    loader: redirectLoader,
  },
  {
    element: <RootLayout />,
    loader: getUserLoader,
    children: [
      {
        path: "/",
        loader: homeRedirectLoader,
      },
      {
        path: "/admins",
        element:<AllAdmins />,
        loader: getAdminUsersLoader,
      },
      {
        path: "/account/create",
        element: <CreateAccountPage />
      },
      {
        path: "/owners",
        element: <Owners />,
        loader: getOwnerLoader,
      },
      {
        path: "/organization/:organization_id",
        element: <SignleOrganization />,
        loader: getSingleOrganizationLoader,
      },
      {
        path: "/audit",
        element: <AuditLogs />
      },
      {
        path: "/settings",
        element: <SettingsPage />
      }
    ],
  },
  {
    path: "*",
    loader: () => redirect("/"),
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#40a9ff",
          fontFamily: "Poppins",

          // Alias Token
        },
      }}
    >
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  );
}

export default App;
