import { useState } from "react";
import dayjs from 'dayjs';
import {
  Modal,
  Typography,
  Form,
  Button,
  Grid,
  Space,
  Alert,
  Input,
  Select,
  DatePicker,
  DatePickerProps,
  Row,
  Col
} from "antd";
import { useRevalidator } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
import { regionList } from "../../regionData";
import { IOwnerSummary } from "../../interface";
import { updateOperator } from "../../apiBridge";

interface Props {
  isOpen: boolean;
  user: IOwnerSummary | null;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  notifController: MessageInstance;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const EditOwnerModal: React.FC<Props> = ({
  isOpen,
  controller,
  notifController,
  reloadController,
  user
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const revalidate = useRevalidator();

  const [loadingState, setLoadingState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinished = async (values: any) => {
    
    setLoadingState(true);

    if (!user) {
      return false;
    }

    const payload = {
      display_name: values.display_name,
      location: values.location,
      first_name: values.first_name,
      last_name: values.last_name,
      organization_id: user.organization?.organization_id,
      organization_name: values.organization_name
    }

    try {
        const res = await updateOperator(user.user_id, payload);
        if (res.status === 200) {              
            notifController.success("update successfully");
            reloadController(true)
            controller(false);
        } else {
            const data = await res.json()
            notifController.error( data.error || "server error" )
        }
    } catch (e: any) {
        notifController.info( e.message )
    }

    setLoadingState(false);
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen}
      // width={380}
      afterOpenChange={(open) => {
        if (open) {
          setErrorMsg("");
          form.resetFields();
        } 
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Edit Operator</Title>
      </div>
      {errorMsg !== "" && (
        <Alert
          type="error"
          message={errorMsg}
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        name="editOwnerForm"
        initialValues={{
          email: user?.email,
          display_name: user?.name,
          location: user?.location,
          first_name: user?.first_name,
          last_name: user?.last_name,
          organization_name: user?.organization?.organization_name
        }}
        onFinish={onFinished}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        validateTrigger="onSubmit"
        requiredMark={false}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col sm={12} xs={24}>
            <Form.Item label="Email" name="email">
              <Input placeholder="hello@world.co" disabled={true} />
            </Form.Item>
          </Col>

          <Col sm={12} xs={24}>
            <Form.Item label="Display Name" name="display_name"
              rules={[
                {},
                ({ getFieldValue }) => ({validator(_, value) {
                    if (!value || value === "") {
                      return Promise.reject(new Error("Please enter a display name."));
                    }

                    if (value.trim() !== value) {
                       return Promise.reject(new Error("The display name cannot have spaces before or after it."))
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Full Name">
          <Input.Group compact style={{ display: 'flex' }}>
            <Form.Item name="first_name" noStyle>
              <Input placeholder="First Name" style={{ flex: 1, marginRight: 8 }} />
            </Form.Item>
            <Form.Item name="last_name" noStyle>
              <Input placeholder="Last Name" style={{ flex: 1 }} />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        
        {
          user?.organization ? <>
            <Form.Item 
              label="Organization Name" 
              name="organization_name"
              rules={[{ required: true, message: "Please input organization name" }]}
            >
              <Input />
            </Form.Item>
          </> : <></>
        }

        <Form.Item 
            label={screens.md ? "Country / Region" : ""}
            name="location">
            <Select>
                <Select.Option key="" value="">unknown country / region</Select.Option>
                                    {regionList.map((item, index) => (
                                        <Select.Option key={index} value={item}>
                                        {item}
                                        </Select.Option>
                                    ))}
              </Select>
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="submit" loading={loadingState}>
                Save Changes
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default EditOwnerModal;
