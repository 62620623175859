
import { useState } from "react";
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Grid,
  Space,
  Row,
  Col,
  Alert,
  InputNumber,
  DatePicker,
} from "antd";import { updateOrganizationPlayer, resetOrganizationPlayerPassword } from "../../apiBridge";
import { IOrganizationPlayerListItem } from "../../interface";
import { MessageInstance } from "antd/es/message/interface";
import { regionList } from "../../regionData";
import dayjs from 'dayjs';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

interface Props {
    isOpen: boolean;
    user: IOrganizationPlayerListItem | null;
    organizationId: string;
    controller: React.Dispatch<React.SetStateAction<boolean>>;
    reloadController: React.Dispatch<React.SetStateAction<boolean>>;
    messageController: MessageInstance;
  }
  
  
  const EditPlayerUserModal: React.FC<Props> = ({
      isOpen, 
      user,
      organizationId,
      controller,
      reloadController,
      messageController
  }) => {
    const screens = useBreakpoint();
    const [form] = Form.useForm();
    
    const [loadingState, setLoadingState] = useState(false);
    const [isResetPwdModelOpen, setIsResetPwdModelOpen] = useState(false)
    const [OTP, setOTP] = useState("");
    const [showPasswordPage, setShowPasswordPage] = useState(false);

    const onFinishEditUser = async (values: any) => {
        if (!user) return false;

        if (values.birth_year) {
            values.birth_year = values.birth_year.year();
        }

        setLoadingState(true);
        try {
            const res = await updateOrganizationPlayer(organizationId, user.id, values);
            setLoadingState(false);
            if (res.status === 200) {
                const data = await res.json();
                messageController.info("user update successfully")

              form.resetFields();
              reloadController(true);
              controller(false)
            }
        } catch (e: any) {
            messageController.info( e.message )
        }
    }

    const openResetPassword = () => {
        if (!user) return false;

        setOTP("")
        setShowPasswordPage(false);
        setIsResetPwdModelOpen(true);
    }

    const closeResetPassword = () => {
        setIsResetPwdModelOpen(false);
    }

    const doResetPassword = async() => {
        controller(false);

        if (!user) return false;

        let res = await resetOrganizationPlayerPassword(organizationId, user.id);
        if (res.status === 200) {
            setShowPasswordPage(true);
            const data = await res.json();
            setOTP(data.password);
        }
    }

    return (<>
        <Modal 
            footer={null} 
            closeIcon={false}
            maskClosable={false} centered 
            open={isOpen} 

            afterOpenChange={(open) => {
                form.resetFields()
            }}
        >
            <div style={{ marginBottom: 20 }}>
                <Title level={4}>
                    Edit User
                </Title>
            </div>

            <Form 
                        form={form} 
                        name="createuserform" 
                        layout="vertical"
                        onFinish={onFinishEditUser}
                        initialValues={{ 
                            username: user?.username, 
                            role: user?.role, 
                            alias: user?.alias,
                            full_name: user?.full_name,
                            birth_year: user?.birth_year ? dayjs(user.birth_year + "-1-1") : null,
                            phone: user?.phone,
                            location: user?.location
                        }}
                    >
                        <Row gutter={24}>
                            <Col sm={12} xs={24}>
                                <Form.Item 
                                    label={screens.md ? "Username" : ""}
                                    extra={
                                    <Text type="secondary" style={{ fontSize: 12 }}>
                                        Username is used by users to login to the portal
                                    </Text>
                                    }
                                    name="username"
                                >
                                    <Input placeholder="Username" disabled />
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                                <Form.Item
                                    label={screens.md ? "Display Name" : ""}
                                    extra={
                                    <Text type="secondary" style={{ fontSize: 12 }}>
                                        Display name is shown in-game
                                    </Text>
                                    }
                                    name="alias"
                                    rules={[
                                    {},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || value === "") {
                                            return Promise.reject(new Error("Please enter a display name."));
                                        }

                                        if (value.trim() !== value) {
                                            return Promise.reject(new Error("The display name cannot have spaces before or after it."))
                                        }

                                        if (value.length > 25) {
                                            return Promise.reject(new Error("The display name must be shorter than 25 characters."))
                                        }

                                        return Promise.resolve();
                                        },
                                    }),
                                    ]}
                                >
                                    <Input placeholder="Display Name" />
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                                <Form.Item name="full_name" label="Full name">
                                    <Input placeholder="Full name" />
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                                <Form.Item name="birth_year" label="Year of birth">
                                    <DatePicker picker="year" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="phone" label="Contact number">
                            <Input placeholder="contact number" />
                        </Form.Item>
                        
                        <Form.Item
                            label={screens.md ? "Role" : ""}
                            extra={
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                Role of user in the organisation
                            </Text>
                            }
                            name="role"
                            rules={[{ required: true, message: "Please select a role!" }]}
                        >
                            <Select disabled={true}>
                                <Select.Option key="user" value="User">
                                    Player <span className="text-muted">(Able to view personal dashboard)</span>
                                </Select.Option>
                                <Select.Option key="gamemaster" value="GameMaster">
                                    GM <span className="text-muted">(Able to manage players and host matches)</span>
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item 
                            label={screens.md ? "Country / Region" : ""}
                            name="location">
                                <Select>
                                    <Select.Option key="" value="">unknown country / region</Select.Option>
                                    {regionList.map((item, index) => (
                                        <Select.Option key={index} value={item}>
                                        {item}
                                        </Select.Option>
                                    ))}
                                </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={openResetPassword}>Reset Password</Button>
                        </Form.Item>

                        <div style={{ textAlign: "right" }}>
                            <Space>
                            <Button onClick={() => controller(false)}>Cancel</Button>

                            <Form.Item style={{ margin: 0 }}>
                                <Button type="primary" htmlType="submit" loading={loadingState}>
                                Update User
                                </Button>
                            </Form.Item>
                            </Space>
                        </div>
                    </Form>

        </Modal>


        <Modal
            footer={null}
            maskClosable={false}
            closeIcon={false}
            centered
            open={isResetPwdModelOpen}
            // width={380}
            >
                <div style={{ marginBottom: 20 }}>
                    <Title level={4}>
                    {showPasswordPage ? "Reset Success" : "Reset Password"}
                    </Title>
                    <Text type="secondary">
                    {showPasswordPage
                        ? "The account password will not be shown once this is closed. Please note it down."
                        : "Are you sure you want to reset password for this user? This will log the user out of their account."}
                    </Text>
                </div>

                {!showPasswordPage ? (
                    <div style={{ textAlign: "right" }}>
                    <Space>
                        <Button onClick={() => setIsResetPwdModelOpen(false)}>Cancel</Button>
                        <Button type="primary" loading={loadingState} onClick={doResetPassword}>
                        Reset Password
                        </Button>
                    </Space>
                    </div>
                ) : (
                    <div>
                    <div
                        style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: 4,
                        padding: "4px 8px",
                        height: 32,
                        backgroundColor: "#f5f5f5",
                        }}
                    >
                        <Text className="customCopyInput" type="secondary" copyable>
                        {OTP}
                        </Text>
                    </div>
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                        <Button onClick={() => closeResetPassword()}>Back to Dashboard</Button>
                    </div>
                    </div>
                )}
        </Modal>
      </>)
};

export default EditPlayerUserModal