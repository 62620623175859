import { Typography, Form, Input, Button, Select, message } from "antd";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { IAuthUser } from "../../interface";
import ChangePwdModal from "./changePwdModal";
import { updateUserInfo } from "../../apiBridge";

const { Title, Text } = Typography;


const SettingsPage :React.FC = () => {

    const [form] = Form.useForm();
    const user = useOutletContext() as {authUser: IAuthUser};

    const [messageApi, contextHolder] = message.useMessage();
    const [isOpenChangePwd, setIsOpenChangePwd] = useState(false);

    const [loadingState, setLoadingState] = useState(false);

    const onFinishUpdate = async (values: any) => {
        console.info("Update Values", values);

        setLoadingState(true)
        const res = await updateUserInfo({
            account_id: user.authUser.account_info.user_id,
            name: values.display_name
        })

        if (res.status == 200) {
            messageApi.success("update name successfully");
        } else {
            const data = await res.json()
            messageApi.info(data.error || 'network error')
        }

        setLoadingState(false)
    };

    const changePassword = () => {
        setIsOpenChangePwd(true);
    }

    return <>
        {contextHolder}
        <ChangePwdModal
            isOpen={isOpenChangePwd}
            controller={setIsOpenChangePwd}
            messageController={messageApi}
            user={user.authUser}
        />


        <div
            style={{
            background: "white",
            borderRadius: 4,
            boxShadow: "0 0 8px 0 #cbcbcb",
            padding: 20,
            }}
        >
            <Title level={4} style={{ marginBottom: 0 }}>Settings</Title>
        </div>
        <div
            style={{
            background: "white",
            borderRadius: 4,
            boxShadow: "0 0 8px 0 #cbcbcb",
            padding: 20,
            marginTop: 20,
            }}
        >
            <Title level={5}>User Information</Title>
            <Form
                name="settinguserform"
                layout="vertical"
                autoComplete="off"
                validateTrigger="onSubmit"
                form={form}
                onFinish={onFinishUpdate}
                requiredMark={false}
                initialValues={{"display_name": user.authUser.account_info.name}}
                >
                    <Form.Item 
                        label="Display Name" 
                        name="display_name"
                        rules={[
                            {},
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || value === "") {
                                  return Promise.reject(new Error("Please enter a display name."));
                                }
              
                                if (value.trim() !== value) {
                                  return Promise.reject(new Error("The display name cannot have spaces before or after it."))
                                }
              
                                if (value.length > 25) {
                                  return Promise.reject(new Error("The display name must be shorter than 25 characters."))
                                }
                                
                                return Promise.resolve();
                              },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <div >
                        <Button
                        type="primary"
                        loading={loadingState}
                        htmlType="submit"
                        >
                        Save
                        </Button>
                    </div>
            </Form>
        </div>

        <div
            style={{
            background: "white",
            borderRadius: 4,
            boxShadow: "0 0 8px 0 #cbcbcb",
            padding: 20,
            marginTop: 20,
            }}
        >
            <Title level={5}>Password</Title>
            <Button type="primary" ghost onClick={() => changePassword()}>
            Change Password
            </Button>
        </div>

    </>
}

export default SettingsPage