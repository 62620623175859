import {
    Modal,
    Typography,
    Button,
    Space,
    Form,
    Input,
    Grid,
    Alert,
    Progress,
  } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { MessageInstance } from "antd/es/message/interface";
import { useState } from "react";
import { IAuthUser } from "../../interface";
import { setAccountPW } from "../../apiBridge";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
const zxcvbn = require("zxcvbn");


interface Props {
    isOpen: boolean;
    controller: React.Dispatch<React.SetStateAction<boolean>>;
    messageController: MessageInstance;
    user: IAuthUser;
}

const ChangePwdModal: React.FC<Props> = ({
    isOpen,
    controller,
    messageController,
    user
}) => {

    const [form] = Form.useForm();
    const [loadingState, setLoadingState] = useState(false);
    const [passwordScore, setPasswordScore] = useState(0);
    const newPW = form.getFieldValue("new_password")

    const onChangePassword = async(values: any) => {
        console.info(values);

        setLoadingState(true);

        const res = await setAccountPW({
            email: user.account_info.email,
            password: values.current_password,
            new_password: values.new_password,
        });
        if (res) {
            const data = await res.json();

            if (data.error) {
                messageController.info(data.error);
            } else {
                messageController.success("password change successfully")
                controller(false);
            }
        }

        setLoadingState(false);

    }

    return <>
        <Modal footer={null} maskClosable={false} closeIcon={false} centered
            open={isOpen}
            afterOpenChange={(open) => {
                form.resetFields();
            }}>

            <div style={{ marginBottom: 20 }}>
                <Title level={4}>Change Password</Title>
            </div>

            <Form name="changepwdform" 
                layout="vertical"
                onFinish={onChangePassword}
                form={form}
                initialValues={{new_password: "", current_password: ""}}
                autoComplete="off">

                <Form.Item 
                    label="Current Password" 
                    name="current_password"
                    rules={[
                        { required: true, message: "Please input your current password" }
                    ]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    name="new_password"
                    style={{marginBottom: 3}}
                    rules={[
                        {},
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const score = zxcvbn(value).score;
                            console.info("password score", score)
                            setPasswordScore(score);
                            if (
                              !value ||
                              value.length < 8 ||
                              !/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(value)
                            ) {
                              return Promise.reject();
                            } else if (score < 2) {
                              return Promise.reject(new Error("password too weak"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                    <Input.Password />
                </Form.Item>

                <div style={{marginBottom: 15}}>
                    <Progress
                        percent={Math.max(passwordScore * 25, 1)}
                        showInfo={false}
                        strokeColor={
                            passwordScore * 25 < 50
                            ? "#ff4d4f"
                            : passwordScore * 25 < 75
                            ? "#ff9e07"
                            : "#00b7b2"
                        }
                    />
                    <div style={{ marginBottom: 12 }}>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            Strength:{" "}
                            <Text type="secondary" style={{ fontSize: "inherit" }}>
                            {passwordScore === 0
                                ? "VERY WEAK"
                                : passwordScore === 1
                                ? "VERY WEAK"
                                : passwordScore === 2
                                ? "WEAK"
                                : passwordScore === 3
                                ? "FAIR"
                                : "STRONG"}
                            </Text>
                        </Text>
                    </div>

                    <div>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            Your password must include:
                        </Text>
                    </div>

                    <div>
                        {/^(?=.*[a-zA-Z]).+$/.test(newPW ? newPW : '') ? (
                            <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                        )}
                        &nbsp;&nbsp;
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            At least 1 letter
                        </Text>
                    </div>

                    <div>
                        {/^(?=.*[0-9]).+$/.test(newPW ? newPW : '') ? (
                            <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                        )}
                        &nbsp;&nbsp;
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            At least 1 number
                        </Text>
                    </div>

                    <div>
                        {newPW && newPW.length >= 8 ? (
                            <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                        )}
                        &nbsp;&nbsp;
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            Minimum of 8 characters
                        </Text>
                    </div>

                    <div>
                        {passwordScore > 1 ? (
                            <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                        )}
                        &nbsp;&nbsp;
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            Minimum WEAK password strength
                        </Text>
                    </div>
                </div>

                <Form.Item
                    label="Retype password"
                    name="retypepassword"
                    dependencies={["new_password"]}
                    rules={[
                        {},
                        ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value && getFieldValue("new_password")) {
                                  return Promise.reject(
                                    new Error("The new password that you entered do not match!")
                                  );
                                } else if (!value) {
                                  return Promise.resolve();
                                } else if (getFieldValue("new_password") === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("The new password that you entered do not match!")
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>


                    <div style={{ textAlign: "right" }}>
                    <Space>
                        <Button onClick={() => controller(false)}>Cancel</Button>

                        <Form.Item style={{ margin: 0 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingState}
                            disabled={
                            !/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(newPW) ||
                            newPW.length < 8
                            }
                        >
                            Change Password
                        </Button>
                        </Form.Item>
                    </Space>
                 </div>
            </Form>
        </Modal>
    </>

};

export default ChangePwdModal;