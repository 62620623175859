import { useState } from "react";
import dayjs from 'dayjs';
import {
  Modal,
  Typography,
  Form,
  Button,
  Grid,
  Space,
  Alert,
  Input,
  Select,
  DatePicker,
  DatePickerProps,
  Checkbox,
  Row,
  Col
} from "antd";
import { useRevalidator } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
import { regionList } from "../../regionData";
import { createOperator } from "../../apiBridge";

interface Props {
  isOpen: boolean;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  notifController: MessageInstance;
  organizationId?: string;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const CreateOwnerModal: React.FC<Props> = ({
  isOpen,
  controller,
  notifController,
  reloadController
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const revalidate = useRevalidator();

  const [loadingState, setLoadingState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [dateDelta, setDateDelta] = useState(-1);

  const needSubscription = Form.useWatch('need_subscription', form);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    const today = new Date();
    const diff = date?.diff(today, 'days');
    setDateDelta(diff);
  };

  const onFinishManual = async (values: any) => {
    
    console.log(dateDelta);
    
    let payload = {
      email: values.email,
      display_name: values.display_name,
      organization_name: values.organization,
      plan_name: values.plan_name || 'Free Access',
      location: values.location,
      first_name: values.first_name,
      last_name: values.last_name,
      end_at_days: -1,
    };

    if (values.need_subscription) {
      if (dateDelta < 0) {
        notifController && notifController.open({
          type: "error",
          content: "Please select a date in the future.",
        });
        setDateDelta(-1);
        return;
      }

      payload.end_at_days = dateDelta + 1;
    }

    setLoadingState(true);
    
    try {
      const res = await createOperator(payload);
      if (res.status === 201) {
        const data = await res.json();
          
        notifController.success("创建成功");
        reloadController(true)
        controller(false);
      }
    } catch (e: any) {
      notifController.info( e.message )
    }

    setDateDelta(-1);
    setLoadingState(false);
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen}
      // width={380}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
          setErrorMsg("");
        }
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Create Operator</Title>
        <Text type="secondary">
        Account creation will be sent via email.
        </Text>
      </div>
      {errorMsg !== "" && (
        <Alert
          type="error"
          message={errorMsg}
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        name="craeteOwnerForm"
        // layout="vertical"
        onFinish={onFinishManual}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        validateTrigger="onSubmit"
        requiredMark={false}
        layout="vertical"
        initialValues={{
          location: ""
        }}
      >
        <Row gutter={24}>
          <Col sm={12} xs={24}>
            <Form.Item label="Email" name="email"
              rules={[ { required: true }, { type: "email" } ]}
            >
              <Input placeholder="hello@world.co" />
            </Form.Item>
          </Col>

          <Col sm={12} xs={24}>
            <Form.Item label="Display Name" name="display_name"
              rules={[
                  {},
                  ({ getFieldValue }) => ({validator(_, value) {
                      if (!value || value === "") {
                        return Promise.reject(new Error("Please enter a display name."));
                      }

                      if (value.trim() !== value) {
                         return Promise.reject(new Error("The display name cannot have spaces before or after it."))
                      }

                      if (value.length > 25) {
                         return Promise.reject(new Error("The display name must be shorter than 25 characters."))
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Full Name">
          <Input.Group compact style={{ display: 'flex' }}>
            <Form.Item name="first_name" noStyle>
              <Input placeholder="First Name" style={{ flex: 1, marginRight: 8 }} />
            </Form.Item>
            <Form.Item name="last_name" noStyle>
              <Input placeholder="Last Name" style={{ flex: 1 }} />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        
        <Form.Item label="Organization Name" name="organization"
           rules={[{ required: true, message: "Please input organization name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item 
            label={screens.md ? "Country / Region" : ""}
            name="location">
            <Select>
                <Select.Option key="" value="">unknown country / region</Select.Option>
                                    {regionList.map((item, index) => (
                                        <Select.Option key={index} value={item}>
                                        {item}
                                        </Select.Option>
                                    ))}
              </Select>
        </Form.Item>

        <Form.Item valuePropName="checked" name="need_subscription">
          <Checkbox>With subscription</Checkbox>
        </Form.Item>

        {
            needSubscription ? <>
              <Row gutter={24}>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label={screens.md ? "Subscription Expired Date" : ""}
                    name="date"
                  >
                    <DatePicker onChange={onChange} minDate={dayjs().add(1, 'd')} allowClear showNow={false} />
                  </Form.Item>
                </Col>

                <Col sm={12} xs={24}>
                  <Form.Item label="Plan Name" name="plan_name">
                    <Input placeholder="Free Access" />  
                  </Form.Item> 
                </Col>
              </Row>
              
            </> : <></>
        }
        

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="submit" loading={loadingState}>
                Create User
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateOwnerModal;
