import { useState } from "react";
import {
  Outlet,
  useNavigate,
  useLocation,
  NavLink,
  useLoaderData,
} from "react-router-dom";
import {
  DashboardOutlined,
  TeamOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
  SafetyCertificateOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Layout,
  Menu,
  Avatar,
  Typography,
  Modal,
  Space,
  Button,
  Grid,
} from "antd";
import logo from "./assets/new_vtkd_logo.png";
import { deleteCookie } from "./apiBridge";
import { IAuthUser } from "./interface";

const { Header, Content, Sider } = Layout;
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const RootLayout = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useLoaderData() as IAuthUser;
  const {
    account_role,
    account_info: { name },
    is_super_admin
  } = data;
  console.log("Root layout");

  const [logoutConfirmModal, setLogoutConfirmModal] = useState(false);
  const [collapsedSider, setCollapsedSider] = useState(!screens.md);

  const logoutUser = () => {
    deleteCookie("token");
    navigate("/login");
  };

  const navList: MenuProps["items"] = [
    ...(account_role === "Administrator"
      ? [
        {key: "admins", icon: <DashboardOutlined />, label: <NavLink to="/admins">Admins</NavLink>},
        {key: "audit", icon:<SafetyCertificateOutlined />, label: <NavLink to="/audit">Audit Log</NavLink> },
      ] : []
    ),
     { key: "owners", icon: <TeamOutlined />, label: <NavLink to="/owners">Operators</NavLink> },
     { key: "settings", icon: <SettingOutlined />, label: <NavLink to="/settings">User Setings</NavLink> }
  ];

  return (
    <Layout>
      <Modal
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered
        open={logoutConfirmModal}
        width={380}
      >
        <div style={{ marginBottom: 20 }}>
          <Title level={4}>Log Out</Title>
          <Text type="secondary">Are you sure you want to log out?</Text>
        </div>
        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => setLogoutConfirmModal(false)}>Cancel</Button>

            <Button type="primary" onClick={logoutUser}>
              Log Out
            </Button>
          </Space>
        </div>
      </Modal>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: "white",
          borderBottom: "1px solid #d3d3d3",
          zIndex: 200,
          padding: screens.md ? undefined : "0 20px",
        }}
      >
        <img src={logo} alt="vtkd_logo" style={{ height: 32 }} />
        <div style={{ width: "100%" }}>
          {screens.md ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Avatar
                icon={<UserOutlined />}
                style={{ marginRight: 10, backgroundColor: "#40a9ff" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text strong>{name}</Text>
                <Text type="secondary" style={{ fontSize: 12 }}>
                  { is_super_admin ? "Super " : "" }
                  {account_role === "CustomerService"
                    ? "Account Manager"
                    : account_role}
                </Text>
              </div>
            </div>
          ) : (
            <MenuOutlined
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => setCollapsedSider(!collapsedSider)}
            />
          )}
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          breakpoint="md"
          collapsedWidth={0}
          collapsed={screens.md ? false : collapsedSider}
          trigger={null}
          style={{
            boxShadow: `0 0 8px 0 ${screens.md ? "#cbcbcb" : "#3b3b3b"}`,
            height: "calc(100vh - 64px)",
            backgroundColor: "#ffffff",
            position: "absolute",
            zIndex: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Menu
              mode="inline"
              selectedKeys={[location.pathname.split("/")[1]]}
              style={{ borderRight: 0 }}
              items={navList}
              onClick={() => setCollapsedSider(true)}
            />
            <Menu
              mode="inline"
              selectedKeys={[location.pathname.split("/")[1]]}
              style={{ borderRight: 0, borderTop: "1px solid #f5f5f5" }}
              items={[
                { key: "logout", icon: <LogoutOutlined />, label: "Logout" },
              ]}
              onClick={() => {
                setCollapsedSider(true);
                setLogoutConfirmModal(true);
              }}
            />
          </div>
        </Sider>
        <Layout
          style={{
            transition: "all 0.2s,background 0s",
            marginLeft: screens.md ? 200 : 0,
          }}
        >
          {!screens.md && !collapsedSider && (
            <div
              className="mask"
              style={{
                height: "calc(100vh - 64px)",
                position: "absolute",
                width: "100%",
                backgroundColor: "#00000073",
                zIndex: 1,
              }}
              onClick={() => setCollapsedSider(true)}
            />
          )}
          <Content>
            <div
              style={{
                padding: 20,
                height: "calc(100vh - 64px)",
                overflowY: "auto",
              }}
            >
              <Outlet context={{ authUser: data }} />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default RootLayout;
