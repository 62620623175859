
import { useState } from "react";
import { Modal, Typography, Form, Input, Button, Space, message, Checkbox } from "antd";
import { deleteOrganizationPlayer, deleteUser, updateUserAccess } from "../../apiBridge";
import { IOrganizationPlayerListItem, IOwnerSummary } from "../../interface";
import { MessageInstance } from "antd/es/message/interface";

const { Title, Text } = Typography;

interface Props {
  isOpen: boolean;
  user: IOwnerSummary | null;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
  messageController: MessageInstance;
}

const DeleteOwnerModal: React.FC<Props> = ({
    isOpen, 
    user,
    controller,
    reloadController,
    messageController
}) => {
    const [form] = Form.useForm();

    
    const [loadingState, setLoadingState] = useState(false);

    const onFinishDeleteUser = async (values: any) => {
        if (!user) return false;
        console.info(values)

        setLoadingState(true);
        if (values.force) {
            const res = await deleteUser(user.user_id, values.confirmation);
            if (res.status === 200) {
                messageController.success('User deleted successfully')
                reloadController(true);
                controller(false);
            }
        } else {
            const res = await updateUserAccess(user.user_id, true);
            if (res.status === 200) {
                messageController.success('User deleted successfully')
                reloadController(true);
                controller(false);
            }
        }

        setLoadingState(false);
    };
    
    return  (
    <Modal
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered
        open={isOpen && user !== undefined}
        width={380}
        afterOpenChange={(open) => {
            if (!open) {
                form.resetFields();
            }
        }}
    >
        <div style={{ marginBottom: 20 }}>
            <Title level={4}>Remove User</Title>
            <Text type="secondary">
            Are you sure you want to remove this user? 
            </Text>
        </div>
        <Form
            name="createuserform"
            layout="vertical"
            onFinish={onFinishDeleteUser}
            autoComplete="off"
            form={form}
            initialValues={{force: false}}
            validateTrigger="onSubmit"
        >
            <Form.Item
                label="Type 'this account name' to confirm remove"
                name="confirmation"
                rules={[
                    { message: "Please type 'this account name' to remove user." },
                    ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!user) {
                            return Promise.reject( new Error('name is empty') );
                        }

                        if (value !== user.name) {
                            return Promise.reject(
                                new Error("Please type the word 'this account name'")
                            );
                        }
                        
                        return Promise.resolve();
                    },
                    }),
                ]}
                >
                <Input />
            </Form.Item>
            <Form.Item valuePropName="checked" name="force">
                <Checkbox>Permanently delete</Checkbox>
            </Form.Item>

            <div style={{ textAlign: "right" }}>
            <Space>
                <Button onClick={() => controller(false)}>Cancel</Button>

                <Form.Item style={{ margin: 0 }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingState}
                    danger
                >
                    Remove User
                </Button>
                </Form.Item>
            </Space>
            </div>
        </Form>

    </Modal>
    )
}

export default DeleteOwnerModal