import {
    Col,
    Row,
    Table,
    type TableColumnsType,
    Typography,
    Form,
    Input,
    Space,
    Tag,
    Grid,
    Button,
    message,
    Modal,
    Checkbox,
    Select,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { regionList } from "../../regionData";
import AccountSearchSelect from "./accountSeachSelect";

const { Title } = Typography;


const CreateAccount: React.FC = () => {    
    
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [loadingState, setLoadingState] = useState(false);
    const [isSameAsAddress, setIsSameAsAddress] = useState(false);


    const AllowRoleTypeSelect = [
        {label: "Admin", value: "Admin"},
        {label: "Distributor", value: "Distributor"},
        {label: "Reseller", value: "Reseller"},
        {label: "Operator", value: "Operator"},
        {label: "Game Master", value: "GameMaster"},
        {label: "Player", value: "Player"}
    ];

    const AccountType = Form.useWatch("role", form);

    const address1 = Form.useWatch('address1', form);
    const address2 = Form.useWatch('address2', form);

    useEffect(() => {
        if (isSameAsAddress) {
          form.setFieldsValue({
            shipping_address1: address1,
            shipping_address2: address2,
          });
        }
    }, [address1, address2, isSameAsAddress, form]);

    const handleSameAsAddressChange = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked;
        setIsSameAsAddress(isChecked);
    
        if (isChecked) {
          const address1 = form.getFieldValue('address1');
          const address2 = form.getFieldValue('address2');
          form.setFieldsValue({
            shipping_address1: address1,
            shipping_address2: address2,
          });
        } else {
          form.setFieldsValue({
            shipping_address1: '',
            shipping_address2: '',
          });
        }
    };

    const onParentChange = (values: any) => {
        console.info("click", values)
    }


    const onFinishCreateAccount = (values: any) => {
        if (values.first_name) {
            values.full_name = [values.first_name, values.last_name].join("\t");
        }


        
        console.info("nowValues", values);
    }
    
    return (
        <>
        {contextHolder}
            <Row>
                <Col span={24}>
                    <div
                        style={{
                        background: "white",
                        borderRadius: 4,
                        boxShadow: "0 0 8px 0 #cbcbcb",
                        padding: 20,
                        }}
                    >

                        <Title level={4}>Create Account</Title>

                        <Form 
                            form={form} 
                            name="createuserform" 
                            layout="vertical"
                            onFinish={onFinishCreateAccount}
                        >
                                <Row gutter={24}>
                                    <Col sm={12} xs={24}>
                                        <Form.Item name="role" label="Account Type">
                                            <Select options={AllowRoleTypeSelect} />
                                        </Form.Item>
                                    </Col>

                                    <Col sm={12} xs={24}>
                                        <Form.Item label="Display Name" name="display_name">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    {
                                        AccountType === "Distributor" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item name="parent" label="Distribution">
                                                    <AccountSearchSelect accountTypes={["Distribution"]} change={onParentChange} />
                                                </Form.Item>
                                            </Col> 
                                        </> : <></>
                                    }

                                    {
                                        AccountType === "Reseller" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item name="parent" label="Reseller Parent">
                                                    <AccountSearchSelect accountTypes={["Distributor"]} change={onParentChange} />
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }

                                    {
                                        AccountType === "Operator" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item name="parent" label="Distributor/Reseller Parent">
                                                    <AccountSearchSelect accountTypes={["Distributor", "Reseller"]} change={onParentChange} />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={24}>
                                                <Form.Item name="organization" label="Organization Name">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }

                                    {
                                        AccountType === "GameMaster" || AccountType === "Player" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Parent Operator" name="parent">
                                                    <AccountSearchSelect accountTypes={["Operator"]} change={onParentChange} />
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }

                                    {
                                        AccountType === "Admin" || 
                                        AccountType === "Distributor" || 
                                        AccountType === "Reseller" ||
                                        AccountType === "Operator" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Email" name="email">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </> : <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Username" name="username">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    }

                                    <Col sm={12} xs={24}>
                                        <Form.Item label="Contact number" name="phone">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    {
                                        AccountType === "Distributor" ||
                                        AccountType === "Reseller" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Address">
                                                    <Input.Group compact>
                                                        <Form.Item name="address1" noStyle>
                                                            <Input placeholder="Address Line1" style={{ marginBottom: 8 }} />
                                                        </Form.Item>
                                                        <Form.Item name="address2" noStyle>
                                                            <Input placeholder="Address Line2" />
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Building Name" name="building_name">
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={12}>
                                                <Form.Item label="Postal Code" name="postal_code">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }

                                    {
                                        AccountType !== "Admin" ? <>
                                        <Col sm={12} xs={12}>
                                            <Form.Item 
                                                label="Country / region"
                                                name="location">
                                                    <Select>
                                                        <Select.Option key="" value="">unknown country / region</Select.Option>
                                                        {regionList.map((item, index) => (
                                                            <Select.Option key={index} value={item}>
                                                            {item}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                            </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }
                                    {
                                        AccountType === "Distributor" || 
                                        AccountType === "Reseller" ? <>

                                            <Col sm={12} xs={24}>
                                                <Form.Item label="State" name="state">
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Shipping Address">
                                                    <Checkbox onChange={handleSameAsAddressChange}>Same as Address</Checkbox>
                                                    <Input.Group compact>
                                                        <Form.Item name="shipping_address1" noStyle>
                                                            <Input placeholder="Address Line1" disabled={isSameAsAddress} style={{ marginBottom: 8 }} />
                                                        </Form.Item>
                                                        <Form.Item name="shipping_address2" noStyle>
                                                            <Input placeholder="Address Line2" disabled={isSameAsAddress} />
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }

                                    {
                                        AccountType === "Admin" ||
                                        AccountType === "GameMaster" ||
                                        AccountType === "Player" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item label="Full Name">
                                                    <Input.Group compact style={{ display: 'flex' }}>
                                                        <Form.Item name="first_name" noStyle>
                                                            <Input placeholder="First Name" style={{ flex: 1, marginRight: 8 }} />
                                                        </Form.Item>
                                                        <Form.Item name="last_name" noStyle>
                                                            <Input placeholder="Last Name" style={{ flex: 1 }} />
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }

                                    {
                                        AccountType === "Distributor" ||
                                        AccountType === "Reseller" ||
                                        AccountType === "Operator" ? <>
                                            <Col sm={12} xs={24}>
                                                <Form.Item label="UEN Number" name="uen_number">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </> : <></>
                                    }
                            </Row>

                            <div >

                            <Form.Item style={{ margin: 0 }}>
                                <Button type="primary" htmlType="submit" loading={loadingState}>
                                 Create Account
                                </Button>
                            </Form.Item>
                        </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CreateAccount