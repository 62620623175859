import dayjs from 'dayjs';
import { useState } from "react";
import { Modal, Typography, Form, Input, Button, Space, message, Checkbox, DatePickerProps, DatePicker } from "antd";
import { createFirstInvoice, createManualSubscription, deleteOrganizationPlayer, deleteUser, disableSubscription } from "../../apiBridge";
import { IOrganizationPlayerListItem, IOwnerSummary } from "../../interface";
import { MessageInstance } from "antd/es/message/interface";
import FeatureModal from "./ownerSubscriptionFeatureModal"

const { Title, Text } = Typography;

interface Props {
  isOpen: boolean;
  user: IOwnerSummary | null;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
  notifController: MessageInstance;
}

const OwnerSubscriptionModal: React.FC<Props> = ({
    isOpen,
    controller,
    notifController,
    user,
    reloadController
}) => {

    const [isOpenManual, SetIsOpenManual] = useState(false);
    const [isOpenFeature, setIsOpenFeature] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    const [planName, setPlanName] = useState('');
    const [dateDelta, setDateDelta] = useState(-1);

    function openManual() {
        SetIsOpenManual(true)
    }

    const closeManual = () => {
        SetIsOpenManual(false);
    }

    const setSubscriptionDate: DatePickerProps['onChange'] = (date, dateString) => {
        const today = new Date();
        const diff = date?.diff(today, 'days');
        setDateDelta(diff);
    };

    const handleDeactivateButton = async () => {
        let subscription_id = user?.organization?.subscription_id;
        if (!subscription_id) {
            return false;
        }

        const confirmation = window.confirm("Are you sure you want to cancel the subscription?");

        if (confirmation) {
            const res = await disableSubscription({ subscription_id });
            if (res.status === 200) {
                controller(false);
                reloadController(true);
            }
            notifController.success("Subscription cancelled successfully.");
        } else {
            notifController.error("Subscription cancellation cancelled.");
        }
    }

    const handleStripeButton = async () => {
        const confirmation = window.confirm("Are you sure you want to create a stripe invoice?");

        if (confirmation) {
            let organization_id = user?.organization?.organization_id;
            if (!organization_id) {
                return false;
            }

            const res = await createFirstInvoice({ organization_id });
            if (res.status === 200) {
                controller(false);
            }
            notifController.success("Stripe invoice created successfully.");
        } else {
            notifController.error("Stripe invoice creation cancelled.");
        }
    }

    const handleCreateManualButton = async() => {
        if (!user?.organization?.organization_id) {
            return false;
        }

        if (dateDelta < 0) {
            notifController && notifController.open({
              type: "error",
              content: "Please select a date in the future.",
            });
            setDateDelta(-1);
            return;
        }

        const payload = {
            organization_id: user.organization.organization_id,
            plan_name: planName || 'Free Access',
            end_at_days: dateDelta + 1,
        };

        setLoadingState(true)
        const res = await createManualSubscription(payload);

        if (res.status === 200) {
            notifController.success("New subscription created")
            SetIsOpenManual(false);
            reloadController(true);
            controller(false);
        } else {
            const data = await res.json();
            notifController.error(data.error || "Unknown Error. Please try again next time.")
        }

        setLoadingState(false);
    }

    const openFeature = () => {
        setIsOpenFeature(true)
    }

    return <>
        <Modal 
            open={isOpen}  
            centered
            footer={null}
            closeIcon={false}
            maskClosable={false}
        >
            <div style={{ marginBottom: 20 }}>
                <Title level={4}>Subscription Managment</Title>
            </div>

            {
                user?.organization ? <>
                    <p>Organization: { user.organization.organization_name }</p>
                </> : <>
                    <p>Account No Organization, maybe not operator role.</p>
                </>
            }

            {
                user?.organization?.plan_name ? <>
                    <p>Plan Name: { user.organization.plan_name }</p>
                    <p>Expire Date: { new Date(user.organization.expire_at).toLocaleDateString() }</p>

                    <Space>
                        {
                            new Date(user.organization.expire_at) < new Date() ? <>
                                <Button onClick={() => handleStripeButton()}>Stripe</Button>
                            </> : <>
                                <Button danger type="primary" onClick={() => handleDeactivateButton()}>Deactivate</Button>
                            </>
                        }
                        <Button onClick={() => openManual()}>Manual</Button>
                        <Button onClick={() => openFeature()}>Features</Button>
                    </Space>
                </> : <>
                    {
                        user?.organization ? <>
                            <Button onClick={() => handleStripeButton()}>Stripe</Button>
                            <Button onClick={() => openManual()}>Manual</Button>
                            <Button disabled={true}>Features</Button>
                        </> : <>

                        </>
                    }
                </>
            }

            <div style={{ textAlign: "right" }}>
                <Space>
                    <Button onClick={() => controller(false)}>Close</Button>
                </Space>
            </div>
        </Modal>

        <Modal centered closeIcon={false} footer={null} open={isOpenManual}>
            <div style={{ marginBottom: 20 }}>
                <Title level={4}>Create Manual Subscription</Title>
                <Text type="secondary">
                Please input the date that the subscription will expire.
                </Text>
            </div>

            <div style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <Input onChange={() => setPlanName}  placeholder="Free Access" />

                </div>
                <DatePicker onChange={setSubscriptionDate} minDate={dayjs().add(1, 'd')} allowClear showNow={false} />
            </div>

            <Space>
                <Button type="primary" onClick={() => handleCreateManualButton()} loading={loadingState}>Create Manual Subscription</Button>

                <Button onClick={() => closeManual()}>Cancel</Button>
          </Space>
        </Modal>

        <FeatureModal isOpen={isOpenFeature} controller={setIsOpenFeature} reloadController={reloadController} user={user} notifController={notifController} />
    </>
};


  export default OwnerSubscriptionModal;