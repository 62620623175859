import { useLoaderData, useNavigate } from "react-router-dom";
import { IOrganization, IOwnerSummary } from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Avatar,
  Button,
  Grid,
  Checkbox,
  Space,
  Tag,
  message
} from "antd";
import Highlighter from "react-highlight-words";
import { PlusOutlined, EditOutlined, DeleteOutlined, ShoppingOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import CreateOwnerModal from "./createOwnerModal";
import DeleteOwnerModal from "./deleteOwnerModal";
import EditOwnerModal from "./editOwnerModal";
import OwnerSubscriptionModal from "./ownerSubscriptionModal";
import { getAllOwners, updateUserAccess } from "../../apiBridge";

const { Title, Link } = Typography;
const { Search } = Input;
const { useBreakpoint } = Grid;


const Owners: React.FC = () => {
  const data = useLoaderData() as IOwnerSummary[];
  const screens = useBreakpoint();
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage();
  //console.log(data);

  const [userData, setUserData] = useState<IOwnerSummary[]>(data);
  const [loadingState, setLoadingState] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<IOwnerSummary[]>(data);
  const [onRecyleBin, setOnRecybin] = useState(false);
  const [triggerTableReload, setTriggerTableReload] = useState(false)

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const [targetUser, setTargetUser] = useState<IOwnerSummary|null>(null)

  useEffect(() => {
    if (userData.length > 0) {
      if (searchValue) {
        const temp = userData.filter((owner) =>
          owner.email.includes(searchValue) || owner.name.includes(searchValue) || owner.organization?.organization_name.includes(searchValue)
        );
        setFilteredData(temp);
      } else {
        const temp = userData.filter(
          (user) =>
              user.blocked === onRecyleBin
        );

        setFilteredData(temp);
      }
    }
  }, [userData, searchValue, onRecyleBin]);

  useEffect(() => {
    const reloadData = async () => {
        const res = await getAllOwners()
        setLoadingState(false);
        setTriggerTableReload(false);
        if (res.status === 200) {
            const temp = await res.json();
            setUserData(temp.users);
        }
    };

    if (triggerTableReload) {
      setLoadingState(true)

      // auth0 need sync data, add timeout
      setTimeout(() => reloadData(), 1000);
    }
  }, [triggerTableReload])

  const updateRecyleBin = () => {
    setOnRecybin(!onRecyleBin);
  }

  const createUser = () => {
    setIsCreateUserModalOpen(true)
  }

  const restoreUser = async (user: IOwnerSummary) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('restore user?')) {
      return false;
    }

    setLoadingState(true)
    const res = await updateUserAccess(user.user_id, false)
    if (res.status == 200) {
      messageApi.success("restore successfully")
      setTriggerTableReload(true);
    } else {
      const data = await res.json();
      messageApi.error(data.error || 'server error')
    }

    setLoadingState(false)
  }

  const editUser = (user: IOwnerSummary) => {
      setTargetUser(user);
      setIsEditUserModalOpen(true);
  }

  const deleteUser = (user: IOwnerSummary) => {
    setTargetUser(user)
    setIsDeleteUserModalOpen(true)
  }

  const openSubscription = (user: IOwnerSummary) => {
    setTargetUser(user);
    setIsSubscriptionModalOpen(true);
  }

  const columns: TableColumnsType<IOwnerSummary> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, row) => (
        <div>
          <Avatar src={row.picture} />
          &nbsp; &nbsp;
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />

          {
            text !== row.email ? <>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchValue]}
                autoEscape
                unhighlightStyle={{ color: "#999", marginLeft: 5 }}
                textToHighlight={row.email ? "(" + row.email.toString() + ")" : ""}
              />
            </> : <></>
          }
        </div>
      ),
    },

    {
      title: "Plan Name",
      key: "plan_name",
      render: (_, row) => (
        <div>
          {row.organization?.plan_name ? (
            row.organization?.plan_name
          ) : (
            <Tag color="red">No active plan</Tag>
          )}
        </div>
      ),
    },

    {
      title: "Organization",
      key: "organization",
      dataIndex: "organization",
      render: (value, row) => (
         <>
          {
            value ? <a onClick={() => navigate("/organization/" + value.organization_id)}>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchValue]}
                autoEscape
                textToHighlight={value.organization_name}
              />
            </a>  : <></>
          }
         </>
      )
    },
    {
      title: "Expired At",
      key: "expired_at",
      dataIndex: "organization",
      sorter: (a, b) => {
        let aValue = a.organization ? a.organization.expire_at : "0001-01-01T00:00:00Z"
        let bValue = b.organization ? b.organization.expire_at : "0001-01-01T00:00:00Z"

        return aValue.localeCompare(bValue)
      },
      render: (_, row) => (
        <div>
          {row.organization && row.organization.expire_at !== "0001-01-01T00:00:00Z" ? (
            new Date(row.organization.expire_at) > new Date() ? (
              <Tag color="green">{new Date(row.organization.expire_at).toLocaleDateString()}</Tag>
            ) : (
              <Tag color="red">{new Date(row.organization.expire_at).toLocaleDateString()}</Tag>
            )
          ) : null}
        </div>
      ),
    },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_, record) => {
        return (record.blocked ? <Space size="middle">
            <Link onClick={() => restoreUser(record)}>Restore</Link>
        </Space> : <Space size="middle">
            <Link onClick={() => openSubscription(record)}><ShoppingOutlined /></Link>
            <Link onClick={() => editUser(record)}><EditOutlined /></Link>
            <Link type="danger" onClick={() => deleteUser(record)}>
                <DeleteOutlined />
            </Link>
        </Space>)
    }
    },
  ];

  return (
    <>
      {contextHolder}

      <DeleteOwnerModal 
        isOpen={isDeleteUserModalOpen}
        user={targetUser}
        controller={setIsDeleteUserModalOpen}
        messageController={messageApi}
        reloadController={setTriggerTableReload}
        />

      <EditOwnerModal 
        isOpen={isEditUserModalOpen}
        user={targetUser}
        controller={setIsEditUserModalOpen}
        notifController={messageApi}
        reloadController={setTriggerTableReload} 
        />

      <OwnerSubscriptionModal
        isOpen={isSubscriptionModalOpen}
        user={targetUser}
        controller={setIsSubscriptionModalOpen}
        notifController={messageApi}
        reloadController={setTriggerTableReload}
        />

      <CreateOwnerModal 
        isOpen={isCreateUserModalOpen} 
        controller={setIsCreateUserModalOpen} 
        notifController={messageApi}
        reloadController={setTriggerTableReload}
      />

      <Row>
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
            <Col flex="auto">
                            <Title level={4}>Operators</Title>
                        </Col>
                        {!screens.xs && (
                            <Col
                                flex="auto"
                                span={screens.xs ? undefined : 24}
                                style={{ display: "flex", justifyContent: "end" }}
                            >
                                <Space size="middle">
                                    <Checkbox onClick={() => updateRecyleBin()} checked={onRecyleBin}>Recycle bin</Checkbox>

                                {!screens.xs && (
                                    <Search
                                    placeholder="Search User"
                                    allowClear
                                    disabled={loadingState}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onSearch={setSearchValue}
                                    value={searchValue}
                                    />
                                )}
                                    <Button 
                                        type="primary"
                                        disabled={loadingState}
                                        onClick={() => createUser()}
                                    >
                                    <PlusOutlined /> Create User
                                    </Button>
                                </Space>
                            </Col>
                        )}

                        {screens.xs && (
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    <Col flex="auto">
                                        <Search
                                        placeholder="Search User"
                                        allowClear
                                        disabled={loadingState}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        onSearch={setSearchValue}
                                        />
                                    </Col>
                                    <Col flex="32px">
                                        <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        disabled={loadingState}
                                        onClick={() => createUser()}
                                        >
                                        {!screens.xs ? "Create User" : ""}
                                        </Button>
                                    </Col>
                                </Row>

                                <Row gutter={[20, 20]}>
                                    <Col>
                                        <Checkbox onClick={() => updateRecyleBin()} checked={onRecyleBin}>Recycle bin</Checkbox>
                                    </Col>
                                </Row>
                            </Col>
                        )}
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  loading={loadingState}
                  rowKey="key"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Owners;
